import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  ip: '',
  location: '',
  locationObject: {}
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIP: (state, action) => {
      state.ip = action.payload;
    },
    setLocation: (state, action) => {
      const { address } =
        action.payload?.resourceSets?.[0]?.resources?.[0] || {};
      state.location = address?.adminDistrict
        ? `${address?.adminDistrict2} ,   ${address?.adminDistrict}`
        : null;
    },
    setLocationIP: (state, action) => {
      const { city, region_code } = action.payload;
      state.locationObject = action.payload;
      state.location = `${city}, ${region_code}`;
    }
  }
});

export const { setIP, setLocation, setLocationIP } = userSlice.actions;

export default userSlice.reducer;

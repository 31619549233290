import { configureStore } from '@reduxjs/toolkit';
import userSlice from './reducers/user';

const env = process.env.environment;
const store = configureStore({
  reducer: {
    user: userSlice
  },
  devTools: env === 'dev',
  //Redux logger middleware to log store states for dev env
  middleware: getDefaultMiddleware =>
    env === 'dev'
      ? getDefaultMiddleware().concat(require('redux-logger').logger)
      : getDefaultMiddleware()
});

export default store;

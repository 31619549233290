import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import store from '@redux/store';
import ErrorBoundary from '@components/errorBoundary';
import '../styles/globals.scss';

if (typeof window !== 'undefined' && process.env.environment == 'dev') {
  const ReactDOM = require('react-dom');
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

export default function App({ Component, pageProps }) {
  return (
    <ErrorBoundary>
      <ReduxProvider store={store}>
        <Component {...pageProps} />
      </ReduxProvider>
    </ErrorBoundary>
  );
}
